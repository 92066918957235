.advantages {
	@include adaptiveValue('margin-top', 155, 110);
	&__container {
		@include adaptiveValue('padding-left', 114, 30);
		@include adaptiveValue('padding-right', 114, 30);
	}
	&__body {
		@include adaptiveValue('margin-bottom', 200, 100);
		display: flex;
		flex-direction: column;
	}
	&__title {
		@include adaptiveValue('margin-bottom', 116, 50);
		display: flex;
		align-self: start;
	}
	&__items {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-auto-rows: 1fr;
		justify-items: stretch;
		column-gap: rem(40);
		@include adaptiveValue('row-gap', 64, 30);
		> .advantages__item:nth-child(1) {
			order: 1;
		}
		> .advantages__item:nth-child(2) {
			order: 2;
		}
		> .advantages__item:nth-child(3) {
			order: 3;
		}
		> .advantages__item:nth-child(4) {
			order: 6;
		}
		> .advantages__item:nth-child(5) {
			order: 5;
		}
		> .advantages__item:nth-child(6) {
			order: 4;
		}
		@media (max-width: em(1140)) {
			grid-template-columns: repeat(2, 1fr);
			> .advantages__item:nth-child(1) {
				order: 1;
			}
			> .advantages__item:nth-child(2) {
				order: 2;
			}
			> .advantages__item:nth-child(3) {
				order: 4;
			}
			> .advantages__item:nth-child(4) {
				order: 3;
			}
			> .advantages__item:nth-child(5) {
				order: 5;
			}
			> .advantages__item:nth-child(6) {
				order: 6;
			}
		}
		@media (max-width: $mobile) {
			grid-template-columns: 1fr;
			> .advantages__item:nth-child(1) {
				order: 1;
			}
			> .advantages__item:nth-child(2) {
				order: 2;
			}
			> .advantages__item:nth-child(3) {
				order: 3;
			}
			> .advantages__item:nth-child(4) {
				order: 6;
			}
			> .advantages__item:nth-child(5) {
				order: 5;
			}
			> .advantages__item:nth-child(6) {
				order: 4;
			}
		}
	}
	&__item {
		position: relative;
		background-color: $mainDark;
		padding: rem(44) rem(26);
		border-radius: rem(27);
		display: flex;
		align-items: center;
		gap: rem(30);
		&::after {
			content: '';
			position: absolute;
			z-index: -1;
			border: 33.5px solid;
			border-color: $mainDark transparent transparent $mainDark;
			border-radius: 15px;
		}
		&:nth-child(even) {
			background-color: $mainYellow;
			&::after {
				border-color: $mainYellow transparent transparent $mainYellow;
			}
			.advantages__text {
				color: #000;
			}
		}
		&:nth-child(1),
		&:nth-child(2) {
			&::after {
				top: calc(50% - 33.5px);
				right: -19px;
				transform: rotate(135deg);
			}
		}
		&:nth-child(3) {
			&::after {
				right: calc(50% - 33.5px);
				bottom: -19px;
				transform: rotate(225deg);
			}
		}
		&:nth-child(4),
		&:nth-child(5) {
			&::after {
				top: calc(50% - 33.5px);
				left: -19px;
				transform: rotate(315deg);
			}
		}
		@media (max-width: em(1140)) {
			&:nth-child(1) {
				&::after {
					top: calc(50% - 33.5px);
					right: -19px;
					transform: rotate(135deg);
				}
			}
			&:nth-child(2) {
				&::after {
					top: calc(100% - 38px);
					right: calc(50% - 33.5px);
					transform: rotate(225deg);
				}
			}
			&:nth-child(3) {
				&::after {
					right: calc(100% - 47px);
					bottom: calc(50% - 33.5px);
					transform: rotate(315deg);
				}
			}
			&:nth-child(4) {
				&::after {
					top: calc(100% - 38px);
					left: calc(50% - 33.5px);
					transform: rotate(225deg);
				}
			}
			&:nth-child(5) {
				&::after {
					top: calc(50% - 33.5px);
					left: calc(100% - 45px);
					transform: rotate(135deg);
				}
			}
		}
		@media (max-width: $mobile) {
			padding: rem(25) rem(28);
			&::after {
				content: '';
				display: none;
			}
		}
	}
	&__icon {
		img {
			min-width: 100%;
		}
		@media (max-width:$mobileSmall){
			img{
				width: rem(60);
				height: rem(60);
			}
		}
	}
	&__text {
	}
}
