.portfolio {
	@include adaptiveValue('margin-top', 150, 30);
	@include adaptiveValue('margin-bottom', 124, 35);
	&__container {
		@include adaptiveValue('padding-left', 114, 30);
		@include adaptiveValue('padding-right', 114, 30);
	}
	&__title {
		@include adaptiveValue('margin-bottom', 124, 60);
		@media (min-width: $mobile) {
			text-align: center;
		}
	}
	&__body {
	}
	&__items {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-auto-rows: 1fr;
		grid-auto-columns: 1fr;
		gap: 0;
		@media (max-width: $mobile) {
			grid-template-columns: repeat(1, 1fr);
		}
		@media (min-width: $mobile) {
			max-width: 90rem;
			box-sizing: content-box;
			margin: 0 auto;
			@include adaptiveValue('padding-left', 114, 30);
			@include adaptiveValue('padding-right', 114, 30);
		}
	}
	&__item {
		position: relative;
		text-align: center;
	}
}
.item-portfolio {
	overflow: hidden;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	&__image {
		overflow: hidden;
		@include adaptiveValue('min-height', 380, 250);
		img {
			transition: all 0.3s ease 0s;
		}
		&::after {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background-color: rgba(37, 37, 37, 0.6);
		}
		@media (any-hover: hover) {
			&:hover {
				img {
					transform: scale(1.1);
				}
			}
		}
	}
	&__content {
		position: relative;
		z-index: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 100%;
		.modal-open & {
			z-index: 0;
		}
	}
	&__title {
		font-family: $fontFamilyImpact;
		font-size: rem(34);
		line-height: math.div(61, 34);
		letter-spacing: 0.005em;
		&:not(:last-child) {
			margin-bottom: rem(24);
		}
	}
	&__button {
		color: #fff;
		padding-top: rem(10);
		padding-bottom: rem(10);
		@include adaptiveValue('padding-left', 28, 18);
		@include adaptiveValue('padding-right', 28, 18);
		background-color: rgba(231, 234, 80, 0.07);
		border: 1px solid #e7ea50;
		border-radius: 40px;
		transition: all 0.3s ease 0s;
		@media (any-hover: hover) {
			&:hover {
				background-color: $mainYellow;
				color: #000;
			}
		}
	}
}
.portfolio-modal {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-width: 80%;
	max-height: 90%;
	width: auto;
	height: auto;
	outline: none;
	padding: 20px;
	@media (max-width: $mobile) {
		width: 100%;
		padding: 0;
		max-width: 100vw;
	}
}
.portfolio-modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(16, 16, 16, 0.7);
	display: flex;
	justify-content: center;
	align-items: center;
}
.slick-track {
	display: flex;
	align-items: center;
}
.slick-next,
.slick-prev {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
	z-index: 2;
	@include adaptiveValue('width', 80, 40);
	@include adaptiveValue('height', 80, 40);
}
.slick-prev {
	@media (max-width: $mobile) {
		left: 10px;
	}
}
.slick-next {
	@media (max-width: $mobile) {
		right: 10px;
	}
}
.modal-image {
	max-width: 100%;
	height: 85vh;
	display: block;
	margin: 0 auto;
	@media (max-width: $mobile) {
		max-height: 570px;
		max-width: 100%;
		height: 100%;
		width: 100%;
	}
}
.cancel-modal {
	position: absolute;
	top: 0;
	right: 0;
	img {
		@include adaptiveValue('width', 50, 42);
		@include adaptiveValue('height', 50, 42);
	}
	@media (max-width: $mobile) {
		top: -60px;
		right: 10px;
	}
}
.slick-arrow {
	&::before {
		content: '';
		display: none;
	}
}
.slick-slide {
	@media (max-width: $mobile) {
		min-height: 100%;
	}
}
