.cost {
	@include adaptiveValue('margin-top', 100, 35);
	@include adaptiveValue('margin-bottom', 100, 35);
	@include adaptiveValue('padding-top', 55, 30);
	@include adaptiveValue('padding-bottom', 120, 30);
	&.gray {
		background-color: rgba(190, 187, 185, 0.12);
	}
	&.center {
		display: flex;
		text-align: center;
		align-items: center;
		justify-content: center;
	}
	&__container {
		@include adaptiveValue('padding-left', 114, 30);
		@include adaptiveValue('padding-right', 114, 30);
	}
	&__title {
		@include adaptiveValue('margin-bottom', 84, 24);
	}
	&__body {
		position: relative;
		margin: 0 auto;
		max-width: rem(1040);
		background: #ffffff;
		border: 1px solid #bebbb9;
		border-radius: 27px;
		&:not(:last-child) {
			@include adaptiveValue('margin-bottom', 140, 24);
		}
	}
	&__number {
	}
	&__item {
		&-center {
			text-align: center;
			align-items: center;
			justify-items: center;
		}
	}
	&__buttons {
		display: flex;
		justify-content: center;
		margin: 0 auto;
		@include adaptiveValue('gap', 100, 10);
		@media (max-width: em(600)) {
			justify-content: space-between;
		}
	}
	&__button {
		display: inline-block;
		padding-top: rem(16);
		padding-bottom: rem(16);
		@include adaptiveValue('min-width', 250, 130);
		background-color: #bebbb9;
		border: 1px solid #6e6862;
		border-radius: 40px;
		min-height: rem(44);
		transition: all 0.3s ease 0s;
		@media (max-width: em(500)) {
			padding: 0;
		}
		@media (any-hover: hover){
			&:hover{
				background: #817a73;
			}
		}
		&-back {
		}
		&-next {
		}
		&-finish {
			padding: 15px;
		}
		&.unactive {
			touch-action: none;
			cursor: default;
			color: #a9a9a9;
			background: rgba(190, 187, 185, 0.5);
		}
	}
	&__finish {
	}
}
.item-cost {
	display: flex;
	align-items: center;
	padding: rem(47) rem(40) rem(47) rem(18);
	@include adaptiveValue('height', 164, 66);
	@media (max-width: $mobile) {
		height: auto;
		padding: rem(20);
	}
	&__icon {
		// img {
		// 	max-width: 100%;
		// 	min-width: 34px;
		// }
		&-1 {
			img {
				width: rem(39);
				height: rem(37);
			}
		}
		&-2 {
			img {
				width: rem(37);
				height: rem(36);
			}
		}
		&-3 {
			img {
				width: rem(38);
				height: rem(30);
			}
		}
	}
	&__text {
		font-weight: 700;
		padding-left: rem(25);
		flex: 1 1 100%;
		&-center {
			padding-left: 0;
		}
		@media (max-width: em(500)) {
			padding-left: rem(10);
			font-weight: 400;
		}
	}
	&__input {
		background-color: rgba(190, 187, 185, 0.5);
		box-shadow: inset 2px 0px 20px rgba(0, 0, 0, 0.25);
		border-radius: 20px;
		max-width: rem(96);
		max-height: rem(70);
		padding: 10px;
		&:focus-visible {
			outline: none;
		}
		&-square {
			@media (max-width: $mobile) {
				max-width: rem(55);
				max-height: rem(37);
			}
		}
	}
	&__metr {
		padding-left: rem(5);
		display: flex;
		color: #000;
		&:last-child {
			font-size: rem(16);
		}
	}
	&__checkboxes {
		display: flex;
		@include adaptiveValue('gap', 100, 5);
	}
	&__checkboxes {
		padding-left: rem(2);
	}
	&__content {
		margin-bottom: rem(50);
	}
	&__select {
		display: flex;
		align-items: center;
		flex: 1 1 100%;
		@media (max-width: $mobile) {
			margin-bottom: rem(15);
		}
	}
	&__small {
		@media (max-width: $mobile) {
			flex-direction: column;
			align-items: start;
		}
	}
}
.select {
	background: rgba(217, 217, 217, 0.07);
	box-shadow: inset 2px 0px 8px rgba(0, 0, 0, 0.25);
	border-radius: 20px;
	cursor: pointer;
	max-width: rem(250);
	margin-left: rem(10);
	position: relative;
	@media (max-width: $mobile) {
		max-width: none;
		width: 100%;
		margin-left: 0;
	}
	&__body {
		display: flex;
		align-items: center;
		gap: rem(25);
		white-space: nowrap;
		padding: rem(20);
		@media (max-width: em(500)) {
			padding: rem(10) rem(10) rem(10) rem(20);
		}
	}
	&__text {
		flex: 1 1 100%;
	}
	&__items {
		width: 100%;
		position: absolute;
		background-color: #fff;
		border-radius: 20px;
		@media (max-width: $mobile) {
			min-width: none;
		}
	}
	&__item {
		padding: rem(12) rem(20);
		cursor: pointer;
	}
	&__button {
		display: flex;
		align-items: center;
	}
	&__arrow {
		transition: all 0.3s ease 0s;
		&.open {
			transform: rotate(180deg);
		}
	}
}
.checkbox {
	display: flex;
	align-items: center;
	gap: rem(10);
	&__input {
		position: relative;
		appearance: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		border: 1px solid #68625c;
		outline: none;
		cursor: pointer;
		&:checked {
			&::before {
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 70%;
				height: 70%;
				background-color: #68625c;
				border-radius: 50%;
			}
		}
	}
	&__text {
		color: #68625c;
	}
}
.input-cost {
	position: relative;
	padding: rem(30) rem(25);
	display: flex;
	align-items: center;
	max-width: 520px;
	background-color: #ffffff;
	border: 1px solid #bebbb9;
	border-radius: 27px;
	margin: 0 auto;
	&:not(:last-child) {
		margin-bottom: rem(50);
	}
	@media (max-width: $tablet) {
		padding: rem(25) rem(20);
	}
	@media (max-width: $mobileSmall) {
		padding: rem(20) rem(15);
	}
	&__icon {
		img {
			width: 20px;
			height: 27px;
		}
	}
	&__input {
		padding-left: rem(30);
		width: 100%;
		flex: 1 1 100%;
		&:focus-visible {
			outline: none;
		}
		&::placeholder {
			font-size: rem(22);
			line-height: math.div(40, 22);
			letter-spacing: 0.005em;
			color: #b9b9b9;
		}
		> .special-label {
			display: none;
		}
		input {
			width: 100%;
			&:focus-visible {
				outline: none;
			}
			&::placeholder {
				font-size: rem(22);
				line-height: math.div(40, 22);
				letter-spacing: 0.005em;
				color: #b9b9b9;
			}
		}
	}
	&__validation-icon {
		padding-left: rem(10);
		&.hidden{
			display: none;
		}
	}
}
.validation-message {
	position: absolute;
	bottom: -25px;
	left: 25px;
	color: #ff0000;
	font-size: rem(14);
	&.hidden{
		display: none;
	}
}
.finish-cost {
	&__content {
	}
	&__title {
		&:not(:last-child) {
			@include adaptiveValue('margin-bottom', 30, 12);
		}
	}
	&__text {
		&:not(:last-child) {
			@include adaptiveValue('margin-bottom', 50, 18);
		}
	}
	&__icon {
		img {
			@include adaptiveValue('width', 136, 60);
			@include adaptiveValue('height', 136, 60);
		}
	}
}
