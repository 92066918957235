@use 'sass:math';
@import './base/mixins.scss';

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap');

@import './base/variables.scss';
@import './base/null.scss';
@import './base/ui.scss';
@import './base/common.scss';

body {
	color: $mainColor;
	font-size: $fontSize;
	font-family: $fontFamily;
	font-weight: 500;
	.lock & {
		touch-action: none;
		overscroll-behavior: none;
		overflow: hidden;
		background-color: rgba(0, 0, 0, 0.92);
	}
	.modal-open & {
		overflow: hidden;
	}
}
.overlay {
	.lock & {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.92);
		z-index: 0;
	}
}
.wrapper {
	width: 100%;
	min-height: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	> main {
		flex: 1 1 auto;
	}
	> * {
		min-width: 0;
	}
}
@if ($responsiveType==1) {
	[class*='__container'] {
		@if ($maxWidthContainer>0) {
			max-width: rem($maxWidthContainer);
			box-sizing: content-box;
			margin: 0 auto;
		}
		@if ($containerPadding>0) {
			padding: 0 rem(math.div($containerPadding, 2));
		}
	}
} @else {
	[class*='__container'] {
		margin: 0 auto;
		@if ($maxWidthContainer>0) {
			max-width: rem($maxWidthContainer);
			box-sizing: content-box;
		} @else {
			@if ($containerPadding>0) {
				padding: 0 rem(math.div($containerPadding, 2));
			}
		}
		@media (max-width: $pc) {
			max-width: rem(970);
		}
		@media (max-width: $tablet) {
			max-width: rem(750);
		}
		@media (max-width: $mobile) {
			max-width: none;
			@if ($containerPadding>0 and $maxWidthContainer>0) {
				padding: 0 rem(math.div($containerPadding, 2));
			}
		}
	}
}

@import './components/header.scss';
@import './components/hero.scss';
@import './components/about.scss';
@import './components/work.scss';
@import './components/advantages.scss';
@import './components/portfolio.scss';
@import './components/cost.scss';
@import './components/footer.scss';
