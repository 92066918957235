$minWidth: 320;
$maxWidth: 1920;
$maxWidthContainer: 1440;
$containerPadding: 40;
$containerWidth: $maxWidthContainer + $containerPadding;

$responsiveType: 1;

$pc: em($containerWidth);
$tablet: em(991.98);
$mobile: em(767.98);
$mobileSmall: em(479.98);

$fontFamily: 'Source Sans Pro';
$fontFamilyImpact: 'Impact';
$fontSize: rem(18);

$mainColor: #fff;
$mainYellow: #e7ea50;
$mainDark: #2e2626;
