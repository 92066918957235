@import '../../scss/base/mixins.scss';

.header {
	position: absolute;
	width: 100%;
	top: 0;
	background-color: rgba(0, 0, 0, 0.75);
	&__container {
		@include adaptiveValue('height', 146, 117);
		display: flex;
		@include adaptiveValue('padding-left', 114, 20);
		@include adaptiveValue('padding-right', 114, 20);
		justify-content: space-between;
		align-items: center;
		gap: 30px;
	}
	&__logo {
		img {
			@include adaptiveValue('width', 114, 80);
		}
		.menu-open & {
			opacity: 0.3;
		}
	}
	&__menu {
	}
}
.menu {
	&__icon {
		display: none;
		transform: rotate(180deg);
		@media (max-width: $mobile) {
			display: flex;
			position: absolute;
			left: calc(251 / 294 * 100%);
			top: 41px;
		}
	}
	&__body {
		@media (max-width: $mobile) {
			display: flex;
			flex-direction: column;
			align-items: start;
			position: fixed;
			gap: rem(36);
			width: 75%;
			height: 100%;
			top: 0;
			left: -100%;
			transition: all 0.3s ease 0s;
			overflow: auto;
			background-color: rgba(255, 255, 255, 0.41);
			@include adaptiveValue('padding-top', 192, 120, 768, 320, 2);
			padding-left: rem(52);
			padding-right: rem(15);
			padding-bottom: rem(30);
		}
		.menu-open & {
			left: 0;
			border-radius: 0px 50px 50px 0px;
			&::before {
				border-radius: 0px 50px 0px 0px;
				left: 0;
			}
		}
	}
	&__list {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		row-gap: rem(25);
		@include adaptiveValue('column-gap', 62, 20);
		@media (max-width: $mobile) {
			flex-direction: column;
			text-align: left;
			align-items: start;
			row-gap: rem(65);
		}
	}
	&__item {
	}
	&__link {
		font-weight: 700;
		font-size: 18px;
		line-height: 23px;
		letter-spacing: 0.05em;
		transition: color 0.3s ease 0s;
		@media (any-hover: hover) {
			&:hover {
				color: $mainYellow;
			}
		}
	}
}
