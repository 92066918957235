.work {
	@include adaptiveValue('margin-top', 100, 55);
	&__container {
		@include adaptiveValue('padding-left', 114, 30);
		@include adaptiveValue('padding-right', 114, 30);
		@include adaptiveValue('margin-bottom', 155, 110);
	}
	&__title {
	}
	&__items {
		@include adaptiveValue('margin-top', 90, 70);
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-auto-rows: 1fr;
		@include adaptiveValue('column-gap', 100, 50);
		@include adaptiveValue('row-gap', 115, 30);
		@media (max-width: $mobile) {
			grid-template-columns: 1fr;
		}
		@media (min-width: $mobile) {
			@include adaptiveValue('padding-left', 35, 0);
			@include adaptiveValue('padding-right', 35, 0);
		}
	}
	&__item {
	}
	&__underline {
	}
}
.item-work {
	position: relative;
	display: flex;
	max-width: rem(512);
	padding: rem(18) rem(22);
	background: #ffffff;
	border: 1px solid #bebbb9;
	border-radius: 27px;
	max-width: 100%;
	@media (max-width: $tablet) {
		justify-content: center;
	}
	@media (max-width: $mobile) {
		justify-content: start;
	}
	@media (any-hover: hover) {
		transition: all 0.3s ease 0s;
		&:hover {
			background-color: rgba(110, 104, 98, 0.13);
		}
	}
	&__number {
	}
	&__body {
		display: flex;
		gap: rem(10);
		align-items: center;
		justify-content: center;
		@media (max-width: $tablet) {
			flex-direction: column;
		}
		@media (max-width: $mobile) {
			flex-direction: row;
		}
	}
	&__icon {
		img {
			width: rem(67);
			height: rem(67);
		}
		@media (max-width:$mobileSmall){
			img {
				width: rem(60);
				height: rem(60);
			}
		}
	}
	&__text {
		padding-left: rem(25);
		@media (max-width: $tablet) {
			padding-left: 0;
		}
	}
}
