[class*='-ibg'] {
	position: relative;
	img {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		object-fit: cover;
	}
}
[class*='-ibg_contain'] {
	img {
		object-fit: contain;
	}
}
.button {
	display: inline-flex;
	@media (max-width: $mobileSmall) {
		display: flex;
		justify-content: center;
	}
	&__text {
		white-space: nowrap;
		padding: rem(20) rem(80);
		border: 1px solid $mainYellow;
		background-color: $mainYellow;
		border-radius: rem(40);
		font-weight: 700;
		font-size: rem(16);
		line-height: math.div(21, 16);
		text-transform: uppercase;
		color: #464646;
		transition: all 0.3s ease 0s;
		@media (any-hover: hover) {
			&:hover {
				background-color: transparent;
				color: #fff;
				border: 1px solid #fff;
			}
		}
	}
}
.scroll-to-top-button {
	position: fixed;
	bottom: 20px;
	@include adaptiveValue('left', 114, 30);
	width: rem(62);
	height: rem(62);
	display: none;
	cursor: pointer;
	z-index: 999;
	@media (min-width: $mobileSmall) {
		&.visible {
			display: flex;
		}
	}
	@media (any-hover: hover) {
		transition: all 0.3s ease 0s;
		&:hover {
			transform: scale(1.1);
		}
	}
}
