.hero {
	background-image: url('../../../public/img/hero.jpg');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	width: 100%;
	@include adaptiveValue('height', 1020, 884);
	&__container {
	}
	&__body {
		padding-top: rem(360);
		@include adaptiveValue('padding-left', 164, 0);
	}
	&__content {
		@include adaptiveValue('padding-bottom', 74, 120);
		max-width: rem(470);
		display: flex;
		flex-direction: column;
		gap: rem(64);
	}
	&__title {
		font-family: $fontFamilyImpact;
		font-weight: 400;
		font-size: rem(34);
		line-height: math.div(41, 34);
		text-transform: uppercase;
		@media (max-width: $mobileSmall) {
			font-size: rem(24);
			line-height: math.div(29, 26);
		}
	}
	&__text {
		font-weight: 400;
		font-size: rem(24);
		line-height: math.div(31, 24);
		color: rgba(255, 255, 255, 0.8);
		@media (max-width: $mobileSmall) {
			font-size: rem(18);
			line-height: math.div(21, 18);
		}
	}
}
.hero-modal {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-width: 80%;
	max-height: 90%;
	width: auto;
	height: auto;
	outline: none;
	padding: 20px;
	z-index: 50;
	@media (max-width: $mobile) {
		width: 100%;
		padding: 0;
		max-width: 100vw;
	}
	&__overlay {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(19, 19, 19, 0.66);
		display: flex;
		justify-content: center;
		align-items: center;
		@media (max-width: $mobile) {
			background-color: rgba(35, 35, 35, 0.93);
		}
	}
	&__body {
		position: relative;
		height: rem(585);
		text-align: center;
		padding: rem(50) rem(30);
		background-color: rgba(255, 255, 255, 0.6);
		min-width: rem(590);
		&-finish {
			display: flex;
			flex-direction: column;
			text-align: center;
			justify-content: center;
			@include adaptiveValue('gap', 100, 66);
		}
		@media (max-width: $mobile) {
			background-color: transparent;
			display: flex;
			flex-direction: column;
			min-width: 0;
			height: 80vh;
		}
	}
	&__title {
		font-family: $fontFamilyImpact;
		font-size: rem(24);
		line-height: math.div(43, 24);
		letter-spacing: 0.005em;
		color: #ffffff;
		&:not(:last-child) {
			@include adaptiveValue('margin-bottom', 100, 60);
		}
	}
	&__text {
		font-size: rem(24);
		line-height: math.div(43, 24);
		letter-spacing: 0.005em;
		color: #ffffff;
		&:not(:last-child) {
			margin-bottom: rem(50);
		}
		@media (max-width: $mobile) {
			&:not(:last-child) {
				margin-bottom: rem(100);
			}
		}
	}
	&__content {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		flex: 1 1 auto;
	}
	&__inputs {
		display: flex;
		flex-direction: column;
		&:not(:last-child) {
			margin-bottom: rem(60);
		}
		@media (max-width: $mobile) {
			flex: 1 1 auto;
		}
	}
	&__input {
		width: 100%;
	}
	&__button {
		padding: rem(15) rem(85);
		display: inline-block;
		background-color: #ffffff;
		border-radius: rem(40);
		font-size: rem(24);
		line-height: math.div(43, 24);
		letter-spacing: 0.005em;
		color: #464646;
		max-width: rem(250);
		margin: 0 auto;
		transition: all 0.3s ease 0s;
		&.unactive {
			opacity: 0.5;
			cursor: default;
		}
		@media (any-hover: hover) {
			&:hover {
				background-color: #d5d2d2;
			}
		}
	}
	&__cancel {
		cursor: pointer;
		position: absolute;
		top: 20px;
		right: 20px;
		@media (max-width: $mobile) {
			top: -20px;
		}
	}
}
