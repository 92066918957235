.about {
	@include adaptiveValue('margin-top', 200, 55);
	&__container {
		@include adaptiveValue('padding-left', 114, 30);
		@include adaptiveValue('padding-right', 114, 30);
	}
	&__body {
		@include adaptiveValue('margin-bottom', 200, 100);
		display: flex;
		column-gap: rem(10);
		@media (max-width: $tablet) {
			flex-direction: column;
			row-gap: rem(25);
		}
	}
	&__image {
		img {
			min-width: 100%;
		}
		@media (max-width: $tablet) {
			img {
				max-width: 100%;
			}
		}
	}
	&__content {
		@media (min-width: $tablet) {
			@include adaptiveValue('padding-top', 90, 30);
			@include adaptiveValue('padding-left', 160, 0);
		}
	}
	&__title {
		@include adaptiveValue('margin-bottom', 134, 25);
	}
	&__text {
		p {
			&:not(:last-child) {
				@include adaptiveValue('margin-bottom', 60, 25);
			}
		}
	}
	&__underline {
	}
}
