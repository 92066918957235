.footer {
	@include adaptiveValue('margin-top', 100, 70);
	background-color: #000;
	&__container {
		@include adaptiveValue('padding-top', 100, 25);
		@include adaptiveValue('padding-bottom', 100, 25);
		@include adaptiveValue('padding-left', 114, 30);
		@include adaptiveValue('padding-right', 114, 30);
	}
	&__items {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		gap: rem(20);
		@media (max-width: $mobileSmall) {
			flex-direction: column;
		}
	}
	&__item {
		@media (max-width: $mobile) {
			flex: 0 0 25%;
		}
	}
}
.item-footer {
	display: flex;
	gap: rem(25);
	align-items: center;
	&__icon {
		img {
			width: rem(30);
			height: rem(30);
		}
	}
	&__text {
		white-space: nowrap;
		transition: all 0.3s ease 0s;
		@media (any-hover: hover) {
			a {
				&:hover {
					color: $mainYellow;
				}
			}
		}
	}
}
