.title {
	font-family: $fontFamilyImpact;
	font-weight: 400;
	@include adaptiveValue('font-size', 64, 26);
	line-height: math.div(115, 64);
	letter-spacing: 0.005em;
	color: rgba(0, 0, 0, 0.45);
}
.text {
	@include adaptiveValue('font-size', 24, 18);
	line-height: math.div(43, 24);
	letter-spacing: 0.005em;
	color: #000000;
	&-advantages {
		color: #ffffff;
	}
}
.divider {
	margin: 0 auto;
	max-width: rem(711);
	height: 1px;
	background-color: #68625c;
	&-work {
		transform: rotate(90deg);
		@include adaptiveValue('max-width', 122, 81);
	}
}
.number {
	font-family: $fontFamilyImpact;
	position: absolute;
	z-index: -1;
	font-weight: 400;
	left: -34px;
	top: -40px;
	font-size: rem(124);
	letter-spacing: 0.005em;
	color: rgba(190, 187, 185, 0.56);
	@media (max-width:$mobileSmall){
		font-size: rem(90);
		left: -24px;
	}
}
